@import "../../styles/variables";

.header {
  display: flex;
  height: 100px;
  background: #fff;

  a {
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
  }

  .left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      margin: 0 25px;
    }
  }

  .logoLink {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    //height: 121px;
    //max-height: 100%;

    //width: 70px;
  }

  .right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 0;

    a {
      margin: 0 10px;
    }
  }

  .mobile {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .header {
    .left {
      display: none;
    }

    .right {
      display: none;
    }

    .mobile {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      margin-left: 0;
      width: 100%;
    }

    .cart {
      padding: 0 10px;
    }
  }

  .mobileMenu {
    padding-bottom: 30px;
    a {
      font-size: larger;
      padding: 15px 0 15px 10px;
      display: block;
    }
  }
}
