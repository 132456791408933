$font-family-sans-serif: Roboto, Helvetica, Arial, sans-serif;
$link-decoration: none;

$primary: #000;

$base-bg: rgba(238, 238, 240, 1);
$enable-rounded: false;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
