@import "../../styles/variables";

.footer {
  background: $black;
  color: $white;

  & > * {
    border-bottom: 1px solid $gray-600;
  }

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }

  .svg {
    filter: invert(100%);
  }

  .logo {
    margin-top: 4rem;
    padding: 2.5rem 0;
  }

  .main {
    padding: 1rem 0;

    ul {
      list-style: none;
      padding: 0;
    }

    .social {
      a {
        padding: 0 10px;
      }
    }
  }

  .copyright {
    color: $gray-400;
    padding: 15px 0;
    font-size: small;
  }

  .payments {
    & > div {
      margin: 0 10px !important;
    }
  }
}

.cookies {
  background: $black;
  border-top: 1px solid $white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
